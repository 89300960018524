/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useQueryClient } from 'react-query';
import { Dispatch, SetStateAction, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { BPV_CONTENT, CONTENT } from './validateTerms.content';
import ValidateTermsEvents from './validateTerms.events';
import { Checkbox } from '../../atoms';
import Terms from '../Terms';
import { QueryKeys } from '../../../constants/queryKeys.const';
import { INewUser } from '../../../interfaces/userData.interface';
import { LINKS } from '../../../constants/routes.const';
import * as S from './styles';

interface IValidateTermsProps {
  setAcceptedTerms: Dispatch<SetStateAction<boolean>>;
  acceptedTerms: boolean;
  mode?: 'bpv' | 'pedal';
}

const ValidateTerms: React.FC<IValidateTermsProps> = ({
  acceptedTerms,
  setAcceptedTerms,
  mode = 'pedal',
}) => {
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData(QueryKeys.userData) as INewUser;
  const [termsDisplay, setTermsDisplay] = useState(false);
  const handleAcceptTermsDialog = (): void => {
    ValidateTermsEvents.termsClick();
    setAcceptedTerms(true);
    setTermsDisplay(false);
  };

  const openTermsDisplay = (): void => {
    ValidateTermsEvents.termsRead();
    setTermsDisplay(true);
  };

  const toggleTerms = (): void => {
    setAcceptedTerms(previousTerms => {
      if (!previousTerms) ValidateTermsEvents.termsClick();
      return !previousTerms;
    });
  };

  const closeTermsDisplay = (): void => setTermsDisplay(false);

  const pdfSource = mode === 'pedal' ? user.location.terms : LINKS.TERMS;

  const termContent = mode === 'pedal' ? CONTENT : BPV_CONTENT;

  return (
    <Box component="section" marginLeft="-9px" marginTop="16px" display="flex" alignItems="center">
      <Box display="flex" alignItems="center">
        <Checkbox
          color="primary"
          checked={acceptedTerms}
          onChange={toggleTerms}
          data-testid="validate-terms"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
        <Typography>
          {termContent.terms}
          <S.Term data-testid="openTerms" onClick={openTermsDisplay}>
            {termContent.openTerms}
          </S.Term>
          {termContent.terms2}
        </Typography>
      </Box>
      <Terms
        open={termsDisplay}
        handleClose={closeTermsDisplay}
        buttonText={CONTENT.acceptTerms}
        src={pdfSource}
        handleAccept={handleAcceptTermsDialog}
      />
    </Box>
  );
};

export default ValidateTerms;

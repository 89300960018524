import { getEnv } from '../utils/env';

const ZENDESK_ID = getEnv('REACT_APP_ZENDESK_ID');

export const CONTA_CLIENTE = {
  CONTA_CLIENTE: '/conta-cliente',
  ATUALIZACAO_CARTAO: '/conta-cliente/atualizacao-cartao',
  CONFIGURACAO_CONTA: '/conta-cliente/configuracao',
  PLANO_CLIENTE: '/conta-cliente/plano',
  CONTA_RECOMPENSA: '/conta-cliente/recompensas',
};

export const BPV = {
  BPV_HOME: '/home-bpv',
  INFO_BIKES: '/info-bikes',
  ONBOARDING: '/onboarding',
  ONBOARDING_FIRST_STEP: '/onboarding/?step=FIRST_STEP',
  ONBOARDING_SECOND_STEP: '/onboarding/?step=SECOND_STEP',
  ONBOARDING_THIRD_STEP: '/onboarding/?step=THIRD_STEP',
  ONBOARDING_FOURTH_STEP: '/onboarding/?step=FOURTH_STEP',
  ONBOARDING_FIFTH_STEP: '/onboarding/?step=FIFTH_STEP',
  WHATSAPP: 'https://api.whatsapp.com/send?phone=551130037302',
  SIGNUP_EMAIL_RESEND: '/signup-email-resend',
  ADD_CARD: '/add-card',
  UPDATE_CARD: '/update-card',
  UPDATE_CARD_CHECK_IN: '/update-card-check-in',
  SIGNUP_PERSONAL: '/signup-personal',
  SIGNUP_PHONE: '/signup-phone',
  CHECKIN_WITHDRAW: '/checkin/?type=withdraw',
  SIGNUP_DOCUMENT: '/signup-document',
  SIGNUP_DOCUMENT_FRONT: '/signup-document-front',
  SIGNUP_DOCUMENT_BACK: '/signup-document-back/',
  SIGNUP_CAMERA_PROOF_ADDRESS: '/signup-camera-proof-address',
  SIGNUP_CONFIRM_DOCUMENT: '/signup-confirm-document',
  SIGNUP_PHONE_CONFIRM: '/signup-phone-confirm',
  SIGNUP_ADDRESS: '/address-bpv',
  SIGNUP_EMAIL: '/signup-email',
  SIGNUP_CARD_SCREEN: '/signup-card',
  BPV_PLANS: '/plans-bpv',
  SCHEDULE_PICKUP_PLACE: '/pickup-place',
  SCHEDULE_CALENDAR: '/schedule-calendar',
  SCHEDULE_CONFIRMATION: '/schedule-confirmation',
  SCHEDULE_DETAIL: '/schedule-detail',
  CHECK_IN: '/checkin-bpv',
  CHECK_IN_DONE: '/checkin-bpv-done',
  BIKE_RELEASED: '/bike-released',
  CANCEL_BPV_RENEW: '/cancel-bpv-renew',
};

export const ROUTES = {
  CADASTRO_CARTAO: '/cadastro/cartao',
  CADASTRO_CLIENTE: '/cadastro',
  CANCELAR_PLANO: '/cancelar-plano',
  ENDERECO: '/endereco',
  ESQUECEU_SENHA: '/esqueceu-senha',
  GERAR_TOKEN_CONTA: '/gerar-token-conta',
  GERAR_TOKEN_LOGIN: '/gerar-token-login',
  HUB: '/hub',
  INFO_VIEW: '/info-view',
  LOGIN: '/login',
  MAIORES_DETALHES: '/maiores-detalhes',
  MELHOR_COMPRA: '/melhor-compra',
  MIGRAR_PLANO: '/resumo-compra/migracao',
  NOVA_SENHA: '/nova-senha',
  PLANOS: '/planos',
  PONTOS_APOIO: '/pontos-apoio',
  RESUMO_COMPRA: '/resumo-compra',
  COMPRA_NOVO_PLANO: '/resumo-compra/novo-plano',
  RETIRADA_DEVOLUCAO: '/retirada-devolucao',
  VIAGEM_BLOQUEIO: '/viagem/bloqueio',
  RETIRADA_DEVOLUCAO_CONFIRMA: '/retirada-devolucao-confirma',
  ROOT: '/',
  SELECAO_PAGAMENTO: '/cadastro/selecao-pagamento',
  TROCAR_CARTAO: '/trocar-cartao',
  MIGRAR_PLANO_SOBRE: '/migrar-plano',
  WARNING_LIST_INFO: '/warning-list-info',
  PEDAL_RESPONSA: '/pedal-responsa',
  WARNING_LIST: '/warning-list',
  ...CONTA_CLIENTE,
  ...BPV,
} as const;

export const LINKS = {
  SUPPORT: `https://tembicihelp.zendesk.com/hc/pt-br/categories/${ZENDESK_ID}`,
  SUPPORT_BPV: `https://tembicihelp.zendesk.com/hc/pt-br/articles/17269365120148-Bike-pra-voc%C3%AA`,
  TERMS:
    'https://tembicihelp.zendesk.com/hc/pt-br/articles/4496778990100-Termos-e-Condi%C3%A7%C3%B5es-de-Uso-iFood-Pedal-e-Bike-Pra-Voc%C3%AA',
  WHATSAPP: 'https://api.whatsapp.com/send?phone=551130037302',
};

type RoutesKeys = keyof typeof ROUTES;
export type AppRoute = typeof ROUTES[RoutesKeys];
